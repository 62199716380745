import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';


import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';

// import p1 from 'assests/Photos/SchoolProgram/2024/SchoolCouncil2024/1.jpg';
// import p2 from 'assests/Photos/SchoolProgram/2024/SchoolCouncil2024/2.jpg';

// import pdf1 from 'assests/Pdf/SchoolCouncil2024.pdf';

import { Typography } from '@mui/material';
import Container from 'components/Container';
import { Sidebar2023 } from 'views/SchoolProgram2023/components';
import Sidebar2024 from '../SchoolProgram2023/components/Sidebar2024';
import Archive2022 from '../SchoolProgram2023/components/Archive2022';


const SchoolCouncil2024 = () => {
    const theme = useTheme();
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const openLightbox = (index) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    };

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });

const [config, setConfig] = useState({ base_image_url: '' });
useEffect(() => {
  fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
    .then(response => response.json())
    .then(data => setConfig(data))
    .catch(error => console.error('Error fetching config:', error));
}, []);

const p1 = `${config.base_image_url}/home/events-activities/school-programs/SchoolCouncil2024/1.webp`;
const p2 = `${config.base_image_url}/home/events-activities/school-programs/SchoolCouncil2024/2.webp`;
const pdf1 = `${config.base_image_url}/home/home-static/static-images/all-static-images/pdf/SchoolCouncil2024.pdf`;
 

    const mock = [
        {
          name: 'School Council-2024',
          title: 'Click here',
          
          href:pdf1,
        },
       
      ];



    const photos = [
        {
            src: p2,
            source: p2,
            rows: 1.5,
            cols: 2,
        },
        {
            src: p1,
            source: p1,
            rows: 1.5,
            cols: 2,
        },
        
    ];

    return (
        <Main>
            <Container>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={8}>
                        <Box>

                            <Box >
                                <Typography variant='h4' align='center'>
                                SCHOOL COUNCIL 2024-25
                                </Typography>
                                <br />
                                {/* <Typography
                                    fontSize="x-small"
                                ><br /><br />
                                 Date: 21 June 2024
                                </Typography> */}
                                {/* <Typography
                                variant={'h6'}
                                color={'primary'}
                                align={'center'}
                                fontWeight={'normal'}
                            ><br />
                                “If you believe in yourself and have dedication and determination and never quit, you'll be a winner. The price of victory is high but so are the rewards.”

                                <br />
                            </Typography> */}

                               
                                {/* <Typography variant={'subtitle1'} align={'justify'}>
                                National Public School Yeshwanthpur embarked on a new academic year 2024-25 with Investiture Ceremony on Friday, 21 June 2024. It marks a significant milestone in the journey of nurturing leadership and excellence among our students. This ceremony is not merely a ritual of conferring titles; it is a solemn occasion where we entrust responsibilities to our future leaders.  
                                    <br></br>
                                    The Investiture Ceremony for the academic year 2024-25 commenced with a proud display of discipline and honour as the cabinet marched in synchronization to the beats of the school band and accorded the guard of honour to our Principal Ms Sheeja Manoj.  The ceremony took an intense turn as each leader stood before their peers, were adorned with badges and flags ready to accept the weight of their duties and responsibilities. With heads held high and hearts filled with determination, they pledged to uphold the values and principles of our  who embody the essence of true leadership to know the way, go the way and show the way. They have been chosen not only for their abilities but for their potential to positively influence others. She further emphasised that, “If you truly desire something, the universe conspires to make it happen. As we entrust these responsibilities upon you, I urge you to lead with integrity, empathy and in a relentless pursuit of excellence”. The Principal also acknowledged the role of the parents in nurturing these values and desired skill sets that paved the way of success for these students.

                                    <br></br>
                                    Students were bestowed with badges in recognition of their roles as various association leaders.
                                    <br></br>
                                    The ceremony concluded with a sense of optimism and anticipation for the academic year 2024-25, as the newly invested leaders embraced their roles with enthusiasm and determination. As they commence on this journey of leadership, guided by the wisdom of faculty and supported by the encouragement of their peers and families, they carry with them the aspirations and dreams of a brighter future for all.

                                   
                                </Typography> */}
                            </Box>
                            {/* <Typography
                                variant={'h6'}
                                color={'primary'}
                                align={'center'}
                                fontWeight={'normal'}
                            ><br />
                                “ Leadership is the capacity to turn vision into reality ”

                                <br />
                            </Typography> */}

                            <Box>
                                <ImageList
                                    variant="quilted"
                                    cols={2}
                                    rowHeight={isMd ? 200 : 100}
                                    gap={isMd ? 16 : 4}
                                >
                                    {photos.map((item, i) => (
                                        <ImageListItem key={i} cols={item.cols} rows={item.rows}>
                                            <LazyLoadImage
                                                height={'100%'}
                                                width={'100%'}
                                                src={item.src}
                                                alt="..."
                                                effect="blur"
                                                onClick={() => openLightbox(i)}
                                                style={{
                                                    objectFit: 'cover',
                                                    filter:
                                                        theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                                                    cursor: 'poiner',
                                                    borderRadius: 8,
                                                }}
                                            />
                                        </ImageListItem>
                                    ))}
                                </ImageList>
                            </Box>
                            {viewerIsOpen && (
                                <Lightbox
                                    mainSrc={photos[currentImage].src}
                                    nextSrc={photos[(currentImage + 1) % photos.length].src}
                                    prevSrc={
                                        photos[(currentImage + photos.length - 1) % photos.length].src
                                    }
                                    onCloseRequest={() => closeLightbox()}
                                    onMovePrevRequest={() =>
                                        setCurrentImage((currentImage + photos.length - 1) % photos.length)
                                    }
                                    onMoveNextRequest={() =>
                                        setCurrentImage((currentImage + 1) % photos.length)
                                    }
                                    reactModalStyle={{ overlay: { zIndex: 1500 } }}
                                />
                            )}
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                    <Box marginBottom={4}>
                            <Sidebar2024/>
                        </Box> 
                        <Box marginBottom={4}>
                            <Archive2022/>
                        </Box>

                    </Grid>
                </Grid>
            </Container>

            <Container>
      <Box>
        <Box marginBottom={4}>
          {/* <Typography
            sx={{
              textTransform: 'uppercase',
            }}
            gutterBottom
            color={'text.secondary'}
            align={'center'}
            fontWeight={700}
          >
            ACHIEVEMENTS 2024
          </Typography> */}
        </Box>
        <Grid container spacing={2}>
          {mock.map((item, i) => (
            <Grid item xs={12} md={4} key={i}>
              <Box
                width={1}
                height={1}
                component={Card}
                boxShadow={0}
                variant={'outlined'}
                bgcolor={'alternate.main'}
              >
                <CardContent sx={{ padding: 3 }}>
                  <ListItem component="div" disableGutters sx={{ padding: 0 }}>
                  
                    <Button 
                    href={item.href}>
                    <ListItemText
                      sx={{ margin: 0 }}
                      primary={item.name}
                      secondary={item.title}
                      primaryTypographyProps={{
                        variant: 'h6',
                        fontWeight: 700,
                      }}
                      secondaryTypographyProps={{ variant: 'subtitle1' }}
                    />
                    </Button>
                  </ListItem>
                </CardContent>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
            
        </Main>

    );
};

export default SchoolCouncil2024;